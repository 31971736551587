import React from 'react';
// import core components
import { PhotoCard } from 'components/sections';
import { queryHelper } from 'helpers';

import { Wrapper } from './styles';

export const CardList = ({ photos, size }) => {
  return (
    <Wrapper>
      <ul className="grid-list">
        {photos &&
          photos.map(photo => {
            return (
              photo.visibility != false && (
                <li key={queryHelper.getUniqueKey(photo)} className={size === 'large' ? `grid-item-large` : 'grid-item'}>
                  <PhotoCard
                    size={size}
                    slug={photo.slug}
                    imgProps={{
                      ...(photo.fluid && { fluid: photo.fluid }),
                      ...(photo.gatsbyImage && { gatsbyImage: photo.gatsbyImage }),
                      ...(photo.src && { src: photo.src }),
                      alt: photo.name,
                    }}
                  >
                    <label>{photo.name}</label>
                  </PhotoCard>
                </li>
              )
            );
          })}
      </ul>
    </Wrapper>
  );
};
