import styled from '@emotion/styled';

export const Wrapper = styled.div`
  .grid-list {
    margin-left: -1rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    list-style: none;

    @media (max-width: ${props => props.theme.breakpoints.l}) {
      margin-right: 20px;
      margin-left: 0;
    }
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      justify-content: space-evenly;
    }
  }

  .grid-item-large {
    width: 100%;
    max-width: 380px;
    margin-top: 20px;
    
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      max-width: 130px;
      margin-left: 18px;
    }

    div {
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        max-width: 130px;
        max-height: 186px;
      }
    }
  }

  .grid-item {
    width: 100%;
    max-width: 155px;
    margin-top: 20px;
    margin-left: 20px;

    @media (max-width: ${props => props.theme.breakpoints.s}) {
      max-width: 130px;
      margin-left: 18px;
    }

    div {
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        max-width: 130px;
        max-height: 186px;
      }
    }
  }
`;
